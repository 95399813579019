@tailwind base;
@tailwind components;
@tailwind utilities;

.anchor-none {
    overflow-anchor: none;
}

::selection {
    @apply bg-blue-300;
}

.dark ::selection {
    @apply bg-blue-800;
}

body {
    -webkit-tap-highlight-color: transparent;
}

.no-long-press {
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
}

.no-drag {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}
